import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, CustomExtendedDataField, FieldCheckboxGroup, FieldCheckbox, Button, FieldLocationAutocompleteInput } from '../../../components';

import css from './SignupForm.module.css';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';
import RIMG1 from './img/esian.svg';
import RIMG2 from './img/student.svg';
import RIMG3 from './img/university.svg';
import { FaArrowLeft } from "react-icons/fa";
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const identity = v => v;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        userFields,
        userType,
        updateUserType,
        signupCheck,
        updateSignupCheck,
        values
      } = fieldRenderProps;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordValidationMessage = intl.formatMessage({
        id: 'SignupForm.passwordValidationMessage',
      });
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );

      const passwordValidation = validators.passwordValidation(passwordValidationMessage); 

      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength,
        passwordValidation,
      );

      // Custom user fields. Since user types are not supported here,
      // only fields with no user type id limitation are selected.
      const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !signupCheck;

      const firstNameStudent = intl.formatMessage({
        id: 'SignupForm.firstNameStudentLabel',
      });
      const lastNameStudent = intl.formatMessage({
        id: 'SignupForm.lastNameStudentLabel',
      });

      const firstNameOrganization = intl.formatMessage({
        id: 'SignupForm.firstNameOrganizationLabel',
      });
      const lastNameOrganization = intl.formatMessage({
        id: 'SignupForm.lastNameOrganizationLabel',
      });

      const firstNameUniversity = intl.formatMessage({
        id: 'SignupForm.firstNameUniversityLabel',
      });
      const lastNameUniversity = intl.formatMessage({
        id: 'SignupForm.lastNameUniversityLabel',
      });

      const signupCheckEsian = intl.formatMessage({
        id: 'SignupForm.signupCheckEsianLabel',
      });

      const signupCheckStudent = intl.formatMessage({
        id: 'SignupForm.signupCheckStudentLabel',
      });
      const esianPageHeading = intl.formatMessage({
        id: 'SignupForm.esianPageHeading',
      });
      const esianOrganizationHeading = intl.formatMessage({
        id: 'SignupForm.esianOrganizationHeading',
      });
      const studentHeading = intl.formatMessage({
        id: 'SignupForm.studentHeading',
      });
      const universityHeading = intl.formatMessage({
        id: 'SignupForm.universityHeading',
      });

      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const [showPassword, setShowPassword] = useState(false);
      const [isNumberIncluded, setNumberIncluded] = useState(false);
      const [isTextIncluded, setTextIncluded] = useState(false);
      const [isSpecialCharecterIncluded, setSpecialCharecterIncluded] = useState(false);
      if (values?.password != undefined) {
        if (((/[A-Z]/g.test(values?.password)) || (/[a-z]/g.test(values?.password)))&& isTextIncluded == false) {
          setTextIncluded(true)
        }
        else if ((!(/[A-Z]/g.test(values?.password)) && !(/[a-z]/g.test(values?.password))) && isTextIncluded == true) {
          setTextIncluded(false)
        }
        else if (/\d+/g.test(values?.password) && isNumberIncluded == false) {
          setNumberIncluded(true)
        }
        else if (!(/\d+/g.test(values?.password)) && isNumberIncluded == true) {
          setNumberIncluded(false)
        }
        else if (/[!@#$%^&*(),.?":{}|<>]/g.test(values?.password) && isSpecialCharecterIncluded == false) {
          setSpecialCharecterIncluded(true)
        }
        else if (!(/[!@#$%^&*(),.?":{}|<>]/g.test(values?.password)) && isSpecialCharecterIncluded == true) {
          setSpecialCharecterIncluded(false)
        }
      }
      else if (values?.password == undefined && (isNumberIncluded == true || isTextIncluded == true || isSpecialCharecterIncluded == true)) {
        setNumberIncluded(false);
        setTextIncluded(false);
        setSpecialCharecterIncluded(false);
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>

          { !userType ?
            <div>
              <h3 className={css.joinUs}><FormattedMessage id="SignUpForm.heading" /></h3>
              <div  className={css.boxContainer}>
                  <div className={css.registerCard} onClick={() => {
                    updateUserType('esian'); 
                    if(typeof localStorage !== 'undefined')
                      localStorage.setItem('userType', 'esian');
                  }}>
                  <Button className="defaultBrandButtonBlue">
                  <FormattedMessage id="SignUpForm.esianButton" />
                  </Button>
                  <img src={RIMG1} />
                  <p>
                  <FormattedMessage id="SignUpForm.esianDescription" />                  
                  </p>
                  </div>
                  <div className={css.registerCard} onClick={() => { 
                    updateUserType('student'); 
                    if(typeof localStorage !== 'undefined')
                      localStorage.setItem('userType', 'student');
                  }}>
                  <Button className="defaultBrandButtonRed" >
                  <FormattedMessage id="SignUpForm.studentButton" />
                  </Button>
                  <img src={RIMG2}/>
                  <p><FormattedMessage id="SignUpForm.studentDescription" />                  
                  </p>
                  </div>
                  <div className={css.registerCard} onClick={() => { 
                    updateUserType('university'); 
                    if(typeof localStorage !== 'undefined')
                      localStorage.setItem('userType', 'university');
                  }}>
                  <Button className="defaultBrandButtonBlue" >
                  <FormattedMessage id="SignUpForm.universityButton" />
                  </Button>
                  <img src={RIMG3}/>
                  <p><FormattedMessage id="SignUpForm.universityDescription" />                  
                  </p>
                  </div>
                </div>

            </div>
          : null }

          { userType == 'esian'?
          <>
          <button className={css.previousButton} onClick={() => {
            updateUserType(false); 
            if(typeof localStorage !== 'undefined')
              localStorage.setItem('userType', false);
            }}> <FaArrowLeft /> <FormattedMessage id="SignUpForm.previousButton" /> </button>
            <div>
              <div  className={css.boxContainer}>
                <div className={css.userBox}>
                  <Button onClick={() => {
                    updateUserType('individualEsian'); 
                    if(typeof localStorage !== 'undefined')
                      localStorage.setItem('userType', 'individualEsian');
                    }}>
                  <FormattedMessage id="SignUpForm.esianType1" />
                  </Button>
                </div>
                <div className={css.userBox}>
                  <Button onClick={() => { 
                    updateUserType('organizationEsian'); 
                    if(typeof localStorage !== 'undefined')
                      localStorage.setItem('userType', 'organizationEsian');
                    }}>
                  <FormattedMessage id="SignUpForm.esianType2" />
                  </Button>
                </div>
              </div>
            </div>
            </>
          : null }

          { userType && userType!='esian' ?
          <>
              <button className={css.previousButton} 
              onClick={() => {
                if(userType.includes('Esian')) {
                  updateUserType('esian'); 
                  if(typeof localStorage !== 'undefined')
                    localStorage.setItem('userType', 'esian');
                } else {
                  updateUserType(false); 
                  if(typeof localStorage !== 'undefined')
                    localStorage.setItem('userType', false);
                }
                }}
              > 
                <FaArrowLeft /> 
                <FormattedMessage id="SignUpForm.previousButton" /> 
              </button>
              <div className={css.joinText}>
                <span className={css.headingLoginBox}>
                  { userType == 'individualEsian' ? esianPageHeading
                    : userType == 'organizationEsian' ? esianOrganizationHeading
                    : userType == 'student' ? studentHeading
                    : userType == 'university' ? universityHeading
                    : null }
                </span>
              </div>
            <div>

            <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={
                    userType == 'organizationEsian' ? firstNameOrganization 
                    : userType == 'university' ? firstNameUniversity 
                    : firstNameStudent 
                  }
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={
                    userType == 'organizationEsian' ? lastNameOrganization 
                    : userType == 'university' ? lastNameUniversity 
                    : lastNameStudent 
                  }
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>
              <br />

              { userType == 'organizationEsian' ? 
                <>
                  <FieldTextInput
                    type="text"
                    id={formId ? `${formId}.organizationName` : 'organizationName'}
                    name="organizationName"
                    label={intl.formatMessage({
                      id: 'SignupForm.organizationNameLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.organizationNamePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.organizationNameRequired',
                      })
                    )}
                  /> <br/>
                  <FieldLocationAutocompleteInput
                      rootClassName={css.locationAddress}
                      inputClassName={css.locationAutocompleteInput}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      id={formId ? `${formId}.organizationAddress` : 'organizationAddress'}
                      name="organizationAddress"
                      label={intl.formatMessage({
                        id: 'SignupForm.organizationAddressLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.organizationAddressPlaceholder',
                      })}
                      useDefaultPredictions={false}
                      format={identity}
                      // valueFromForm={values.Address}
                      validate={validators.composeValidators(
                        validators.autocompleteSearchRequired(addressRequiredMessage),
                        validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                      )}
                      hideIcon={true}                      
                      isdisplayAllCountries={true}
                    /> <br />
                </>
              : null}

              { userType == 'university' ? 
                <>
                  <FieldTextInput
                    type="text"
                    id={formId ? `${formId}.universityName` : 'universityName'}
                    name="universityName"
                    label={intl.formatMessage({
                      id: 'SignupForm.universityNameLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.universityNamePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.universityNameRequired',
                      })
                    )}
                  /> <br/>
                  <FieldLocationAutocompleteInput
                      rootClassName={css.locationAddress}
                      inputClassName={css.locationAutocompleteInput}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      id={formId ? `${formId}.universityAddress` : 'universityAddress'}
                      name="universityAddress"
                      label={intl.formatMessage({
                        id: 'SignupForm.universityAddressLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.universityAddressPlaceholder',
                      })}
                      useDefaultPredictions={false}
                      format={identity}
                      // valueFromForm={values.Address}
                      validate={validators.composeValidators(
                        validators.autocompleteSearchRequired(addressRequiredMessage),
                        validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                      )}
                      hideIcon={true}
                      isdisplayAllCountries={true}
                    /> <br />
                </>
              : null}

              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              /> 

              <div className={css.eyeIcon} onClick={() => { setShowPassword(!showPassword)}}>
              { showPassword ? 
                <EyeInvisibleOutlined />
              : <EyeOutlined />
              }
              </div>

              <FieldTextInput
                className={css.password}
                type={showPassword ? 'text' : 'password'}
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
            
              {/* <div className={css.customFields}>
                {userFieldProps.map(fieldProps => (
                  <CustomExtendedDataField {...fieldProps} />
                ))}
              </div> */}
                <div className={css.passwordCap}>
                  <div style={{ backgroundColor: isTextIncluded ? 'rgb(4, 113, 18)' : 'rgb(219, 216, 216)' }}>Aa</div>
                  <div style={{ backgroundColor: isNumberIncluded ? 'rgb(4, 113, 18)' : 'rgb(219, 216, 216)' }}>123</div>
                  <div style={{ backgroundColor: isSpecialCharecterIncluded ? 'rgb(4, 113, 18)' : 'rgb(219, 216, 216)' }}>?!@</div>
                </div>
              <br />
              <FieldCheckbox
                name="signupCheck"
                id="signupCheck"
                label={userType == 'student' ? signupCheckStudent : signupCheckEsian}
                // validate={validators.requiredFieldArrayCheckbox(
                //   intl.formatMessage({ id: 'AuthenticationPage.termsAndConditionsAcceptRequired' })
                // )}
                onChange={(e) => {updateSignupCheck(e.currentTarget.checked)}}
              />
            </div>
            </>
          : null }

          <div className={css.bottomWrapper}>
            { !userType || userType == 'esian' ?  null : termsAndConditions }
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
